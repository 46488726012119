const ActionTypes = {
  REDIRECT_URL: 'fetching/REDIRECT_URL',
  // fetching
  START_FETCHING: 'fetching/START_FETCHING',
  FETCH_SUCCESS: 'fetching/FETCH_SUCCESS',
  START_FETCHING_SELECT: 'fetching/START_FETCHING_SELECT',
  STOP_FETCHING_SELECT: 'fetching/STOP_FETCHING_SELECT',
  FETCH_PAGER_SUCCESS: 'fetching/FETCH_PAGER_SUCCESS',
  CREATE_SUCCESS: 'fetching/CREATE_SUCCESS',
  UPDATE_SUCCESS: 'fetching/UPDATE_SUCCESS',
  DELETE_SUCCESS: 'fetching/DELETE_SUCCESS',
  UPDATE_MANY_SUCCESS: 'fetching/UPDATE_MANY_SUCCESS',
  CLEAR_SUCCESS: 'fetching/CLEAR_SUCCESS',
  STOP_FETCHING: 'fetching/STOP_FETCHING',

  // notification
  SHOW_NOTIFICATION: 'notification/SHOW_NOTIFICATION',
  RESET_NOTIFICATION: 'notification/RESET_NOTIFICATION',

  // alert
  SHOW_ALERT: 'alert/SHOW_ALERT',
  HIDE_ALERT: 'alert/HIDE_ALERT',

  // loading
  LOADING_START: 'loading/LOADING_START',
  LOADING_END: 'loading/LOADING_END',

  // category
  FETCH_CATEGORY_SUCCESS: 'category/FETCH_CATEGORY_SUCCESS',
  FETCH_CATEGORY_PAGER_SUCCESS: 'category/FETCH_CATEGORY_PAGER_SUCCESS',
  CREATE_CATEGORY_SUCCESS: 'category/CREATE_CATEGORY_SUCCESS',
  UPDATE_CATEGORY_SUCCESS: 'category/UPDATE_CATEGORY_SUCCESS',
  DELETE_CATEGORY_SUCCESS: 'category/DELETE_CATEGORY_SUCCESS',
  LOAD_DATA_FROM_OPTION: 'cateogry/LOAD_DATA_FROM_OPTION',

  // user
  USER_LOGGING_IN: 'user/USER_LOGGING_IN',
  USER_LOGGED_IN: 'user/USER_LOGGED_IN',
  USER_LOGGED_OUT: 'user/USER_LOGGED_OUT',
  USER_REGISTERED: 'user/USER_REGISTERED',
  USER_AUTH_ERROR: 'user/USER_AUTH_ERROR',
  USER_VERIFIED: 'user/USER_VERIFIED',

  // subscription
  SUBSCRIBE_START: 'subscribe/LOAD_START',
  SUBSCRIBE_SUCCESS: 'subscribe/LOAD_SUCCESS',
  UNSUBSCRIBE_SUCCESS: 'unsubscribe/LOAD_SUCCESS',
  SUBSCRIBE_ERROR: 'subscribe/LOAD_ERROR',

  SUBSCRIBE_QUEUE_SUCCESS: 'subscribe/SUBSCRIBE_QUEUE_SUCCESS',

  // config

  UPLOAD_FILE_SUCCESS: 'upload/UPLOAD_FILE_SUCCESS',
  CLEAR_UPLOAD_SUCCESS: 'upload/CLEAR_UPLOAD_SUCCESS',

  // form
  FETCH_FORM_FIELD_ERROR_SUCCESS: 'form/FETCH_FORM_FIELD_ERROR',
  FETCH_RESET_FORM_FIELD_ERROR_SUCCESS: 'form/FETCH_RESET_FORM_FIELD_ERROR_SUCCESS',

  // support
  CLEAR_RESET_USER_PASSWORD: 'support/CLEAR_RESET_USER_PASSWORD',
  FETCH_RESET_USER_PASSWORD: 'support/FETCH_RESET_USER_PASSWORD'
}

export default ActionTypes
