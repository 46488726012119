export const errorCode = {
  LOCKED_ACCOUNT: 'LOCKED_ACCOUNT',
  SUSPENDED_ACCOUNT: 'SUSPENDED_ACCOUNT',
  UNAUTHENTICATED: 'UNAUTHENTICATED',
  SPAM_ERROR: 'SPAM_ERROR',
  CONTAIN_BAD_WORDS: 'CONTAIN_BAD_WORDS',
  NAME_EXIST: 'NAME_EXIST',
  CODE_EXIST: 'CODE_EXIST',
  EMAIL_EXIST: 'EMAIL_EXIST',
  USERNAME_EXIST: 'USERNAME_EXIST',
  FLOW_DUPLICATE: 'FLOW_DUPLICATE',
  BANNED_WORD_DUPLICATE: 'BANNED_WORD_DUPLICATE',
  BAD_USER_INPUT: 'BAD_USER_INPUT',
  CANNOT_ACCESS: 'CANNOT_ACCESS',
  TITLE_EXIST: 'TITLE_EXIST',
  DURATION_EXIST: 'DURATION_EXIST',
  HIDDEN: 'HIDDEN',
  NOT_FOUND: '404',
  SLUG_EXIST: 'SLUG_EXIST',

  USER_NOT_FOUND: 'USER_NOT_FOUND',

  PROMO_CODE_EXIST: 'PROMO_CODE_EXIST',

  PHONE_NUMBER_EXIST: 'PHONE_NUMBER_EXIST'
}